import { axiosClient } from "./axiosClient";

export async function postDocument(
  userToken: string,
  file: File
): Promise<string> {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);

  return new Promise((resolve) => {
    axiosClient
      .post("/Edenchat/UploadFileInBlobStorage", bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("BFF response.status was not 200");
        }
        const conversationId: string = response.data.conversation_id;
        resolve(conversationId);
      })
      .catch((error) => {
        if (
          !!error.response &&
          error.response.data === "the process to embed the file took too long"
        ) {
          console.error("post document failed:", error);
        } else {
          console.error(error);
        }
        resolve("post_failed");
      });
  });
}
