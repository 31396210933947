import { useState, useEffect, KeyboardEvent } from "react";
import { ModelsType } from "../utils/types/models";
import { ChooseModel } from "./ChooseModel";
import { Button } from "@mui/material";

export interface TopButtonsProps {
  handleNewChat: () => void;
  handleOpenLibrary: () => void;
  historyOpened: boolean;
  isEmpty: boolean;
  libraryOpened: boolean;
  modelSelected: ModelsType;
  transition: string;
  openHistory: () => void;
  historyUpdate: () => void;
  conversationTitle: string;
  convId: string | null;
  handleRenameConversation: (convId: string, newTitle: string) => Promise<void>;
}

export function TopButtons({
  handleNewChat,
  handleOpenLibrary,
  historyOpened,
  isEmpty,
  libraryOpened,
  modelSelected,
  convId,
  handleRenameConversation,
  historyUpdate,
  conversationTitle,
}: Readonly<TopButtonsProps>): JSX.Element {
  const [editingTitle, setEditingTitle] = useState(false);
  const [localTitle, setLocalTitle] = useState(conversationTitle);

  useEffect(() => {
    setLocalTitle(conversationTitle);
  }, [conversationTitle]);

  async function saveTitle() {
    if (convId && localTitle.trim() !== conversationTitle.trim()) {
      await handleRenameConversation(convId, localTitle.trim());
      historyUpdate();
    }
    setEditingTitle(false);
  }

  function cancelEdit() {
    setLocalTitle(conversationTitle);
    setEditingTitle(false);
  }

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      e.preventDefault();
      saveTitle();
    } else if (e.key === "Escape") {
      cancelEdit();
    }
  }

  return (
    <div className="flex w-full flex-col p-4 gap-2">
      <div className="flex w-full flex-col md:flex-row justify-start items-start gap-[10px]">
        {!historyOpened && (
          <>
            <button
              onClick={handleNewChat}
              className=" flex shrink-0 items-center justify-center bg-[#262626] text-white font-ubuntu font-bold text-[14px] leading-[20px] normal-case py-[7px] px-[12px] rounded-[12px] border-2 border-[#262626] hover:bg-gray-600 hover:border-gray-600 focus:border-white/40 whitespace-nowrap overflow-hidden"
            >
              New conversation
            </button>

            {!libraryOpened && (
              <button
                onClick={handleOpenLibrary}
                className="flex shrink-0 items-center justify-center bg-white text-[#262626] font-ubuntu font-bold text-[14px] leading-[20px] normal-case py-[7px] px-[12px] rounded-[12px] border-2 border-[#262626] hover:text-[#737373] hover:border-[#737373]  focus:text-[#a3a3a3] focus:border-[#a3a3a3] whitespace-nowrap overflow-hidden"
              >
                Start with an assistant
              </button>
            )}

            {conversationTitle && convId && (
              <div className="flex grow justify-center mr-[20%] items-center gap-2">
                {!editingTitle ? (
                  <>
                    <p
                      className="font-bold text-gray-500
                    text-sm whitespace-nowrap text-ellipsis overflow-hidden capitalize max-w-[220px] md:max-w-[350px] lg:max-w-[460px]"
                    >
                      {conversationTitle}
                    </p>
                    <Button
                      onClick={() => setEditingTitle(true)}
                      title="Rename conversation"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4 text-gray-300"
                      >
                        <g id="edit">
                          <path
                            id="Vector"
                            d="M11 2.00001H9C4 2.00001 2 4.00001 2 9.00001V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13M14.91 4.15001C15.58 6.54001 17.45 8.41001 19.85 9.09001M16.04 3.02001L8.16 10.9C7.86 11.2 7.56 11.79 7.5 12.22L7.07 15.23C6.91 16.32 7.68 17.08 8.77 16.93L11.78 16.5C12.2 16.44 12.79 16.14 13.1 15.84L20.98 7.96001C22.34 6.60001 22.98 5.02001 20.98 3.02001C18.98 1.02001 17.4 1.66001 16.04 3.02001Z"
                            stroke="gray"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                    </Button>
                  </>
                ) : (
                  <input
                    value={localTitle}
                    onChange={(e) => setLocalTitle(e.target.value)}
                    onKeyDown={handleKeyDown}
                    autoFocus
                    className="border-b-2 border-gray-500 focus:outline-none text-sm font-normal px-1 w-auto text-ellipsis overflow-hidden"
                  />
                )}
              </div>
            )}
            <ChooseModel modelSelected={modelSelected} />
          </>
        )}
      </div>
    </div>
  );
}
