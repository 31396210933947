// src/App.tsx

import { useEffect } from "react";
import {
  MsalAuthenticationTemplate,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./auth/authConfig";
import { Box } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { PageLayout } from "./components/PageLayout";
import RedirectHandler from "./components/RedirectHandler";
import { useUserProfile } from "./hooks/useUserProfile";
import { pushDataLayerEvent } from "./utils/dataLayerUtils";

const NAVBAR_WIDTH = 0;

function App() {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      <RedirectHandler />
      <AuthenticatedTemplate>
        <AuthenticatedApp />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div>
          <p>Loading authentication...</p>
        </div>
      </UnauthenticatedTemplate>
      <ToastContainer />
    </MsalAuthenticationTemplate>
  );
}

function AuthenticatedApp() {
  const userProfile = useUserProfile();

  useEffect(() => {
    if (userProfile) {
      const envWork = process.env.NODE_ENV ?? "development";
      const website = "EdenChat";

      const userData = {
        user_id: userProfile.userId,
        user_location: userProfile.userLocation,
        user_language: userProfile.userLanguage,
        user_bu: userProfile.userBusinessUnit,
        user_company: userProfile.userCompany,
        user_jobtitle: userProfile.userJobTitle,
      };

      pushDataLayerEvent({
        event: "login",
        website,
        env_work: envWork,
        user_data: userData,
      });
    }
  }, [userProfile]);

  return (
    <div className="flex flex-col h-full">
      <Box className="flex flex-row h-screen">
        <PageLayout navbarWidth={NAVBAR_WIDTH} />
      </Box>
    </div>
  );
}

export default App;
