import { Box, Button } from "@mui/material";
import { PropsWithChildren } from "react";

import COLORS from "../../utils/styles/colors";
import { CustomIcon } from "../base-ui";
import { useUserProfile } from "../../hooks/useUserProfile";
import { pushDataLayerEvent } from "../../utils/dataLayerUtils";
import { LinkType } from "@app/utils/types/nav-link";

const grey100 = COLORS.BASE.GREY[100];
const grey200 = COLORS.BASE.GREY[200];
const buttonSx = {
  bgcolor: grey100,
  borderRadius: "8px",
  padding: "8px",
  ":hover": { bgcolor: grey200 },
};

export interface NavLinkProps extends PropsWithChildren {
  readonly link: LinkType;
  readonly selected: boolean;
  readonly transition: string;
  readonly openHistory: () => void;
  readonly closeHistory: () => void;
  readonly historyOpened: boolean;
  readonly goToHomePage: () => void;
}

export function NavLink({
  openHistory,
  closeHistory,
  historyOpened,
  transition,
  goToHomePage,
}: NavLinkProps): JSX.Element {
  const userProfile = useUserProfile();

  function handleOpenHistory() {
    if (userProfile) {
      const envWork = process.env.NODE_ENV ?? "development";
      const website = "EdenChat";

      const userData = {
        user_id: userProfile.userId,
        user_location: userProfile.userLocation,
        user_language: userProfile.userLanguage,
        user_bu: userProfile.userBusinessUnit,
        user_company: userProfile.userCompany,
        user_jobtitle: userProfile.userJobTitle,
      };

      pushDataLayerEvent({
        event: "history_request",
        website,
        env_work: envWork,
        user_data: userData,
      });
    }

    openHistory();
  }

  return (
    <Box className="flex flex-row md:flex-col gap-4 space-between md:justify-start">
      <Button className="h-10 w-auto border-rounded-2" onClick={goToHomePage}>
        <img
          alt="Logo Edenred"
          draggable={false}
          src="/edenred-E-logo.webp"
          style={{ height: "100%" }}
        />
      </Button>

      {historyOpened ? (
        <Button
          className={`flex items-center justify-center ${transition}`}
          onClick={closeHistory}
          sx={buttonSx}
        >
          <CustomIcon filename="sidebar-left-base-grey-dark.svg" />
        </Button>
      ) : (
        <Button
          className={`flex items-center justify-center ${transition}`}
          onClick={handleOpenHistory}
          sx={buttonSx}
        >
          <CustomIcon filename="sidebar-right-base-grey-dark.svg" />
        </Button>
      )}
    </Box>
  );
}
