import { Box, Divider, Link, Tooltip } from "@mui/material";
import { PropsWithChildren, useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import TagManager from "react-gtm-module";

import COLORS from "../../utils/styles/colors";
import { CustomIcon } from "../base-ui";
import { NavLink } from "./NavLink";
import { mockConfig } from "../../utils/mock-data/mockConfig";
import { Log } from "./Log";

const grey200 = COLORS.BASE.GREY[200];
const backgroundGrey = COLORS.SYSTEM.INFO.BACKGROUND;
const shadowColor = COLORS.NAV_SHADOW;

const helpUrl = mockConfig.external_urls.help;
const feedbackUrl = mockConfig.external_urls.feedback;

const links = [{ href: "/", iconFilename: "search-status", name: "Chat" }];

export interface NavigationProps extends PropsWithChildren {
  readonly navbarWidth: number;
  readonly openHistory: () => void;
  readonly closeHistory: () => void;
  readonly historyOpened: boolean;
  readonly goToHomePage: () => void;
}

export function Navigation({
  navbarWidth,
  openHistory,
  closeHistory,
  historyOpened,
  goToHomePage,
}: NavigationProps): JSX.Element {
  const { accounts, instance } = useMsal();
  const userId = accounts[0]?.homeAccountId ?? "";
  const userLanguage = navigator.language ?? "en";
  const [userLocation, setUserLocation] = useState("Unknown");
  const [userBusinessUnit, setUserBusinessUnit] = useState("Unknown");

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        const authResult = await instance.acquireTokenSilent({
          scopes: ["User.Read"],
          account: accounts[0],
        });

        const accessToken = authResult.accessToken;

        const response = await fetch("https://graph.microsoft.com/v1.0/me", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch user profile: ${response.statusText}`
          );
        }

        const userProfile = await response.json();

        const officeLocation = userProfile.officeLocation ?? "Unknown";
        const businessUnit = userProfile.jobTitle ?? "Unknown";

        setUserLocation(officeLocation);
        setUserBusinessUnit(businessUnit);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    }

    fetchUserProfile();
  }, [accounts, instance]);

  const pathname = window.location.pathname;

  function handleHelpClick() {
    const dataLayerArgs = {
      dataLayer: {
        event: "help",
        website: "EdenChat",
        env_work: process.env.NODE_ENV ?? "development",
        user_data: {
          user_id: userId,
          user_location: userLocation,
          user_language: userLanguage,
          user_bu: userBusinessUnit,
        },
      },
    };
    TagManager.dataLayer(dataLayerArgs);
  }

  function handleFeedbackClick() {
    const dataLayerArgs = {
      dataLayer: {
        event: "feedback",
        website: "EdenChat",
        env_work: process.env.NODE_ENV ?? "development",
        user_data: {
          user_id: userId,
          user_location: userLocation,
          user_language: userLanguage,
          user_bu: userBusinessUnit,
        },
      },
    };
    TagManager.dataLayer(dataLayerArgs);
  }

  return (
    <Box
      // Sur mobile : barre du bas, horizontale
      // Sur desktop : sidebar gauche, verticale
      className={`
        flex 
        fixed bottom-0 left-0 w-full h-[60px] z-50 items-center justify-between gap-2 px-4 py-2
        bg-white border-t border-gray-200 shadow-lg flex-row

        md:relative md:flex-col md:h-full md:items-center md:justify-between md:gap-4 md:py-4 md:px-4
      `}
      sx={{
        // Sur desktop, on garde la largeur
        width: { md: `${navbarWidth}px` },
        boxShadow: { md: `0px 20px 30px 0px ${shadowColor}` },
        borderRight: { md: `1px solid ${grey200}` },
      }}
    >
      {/* Top section */}
      <Box className="flex items-center justify-center gap-2 flex-row  md:flex-col md:gap-4">
        {links.map((link) => (
          <NavLink
            key={`${link.href}-${link.name}`}
            link={link}
            selected={pathname === link.href}
            transition=""
            openHistory={openHistory}
            closeHistory={closeHistory}
            historyOpened={historyOpened}
            goToHomePage={goToHomePage}
          />
        ))}

        <div className="hidden md:block py-3">
          <Divider sx={{ borderColor: grey200, width: "48px" }} />
        </div>

        <div className="flex items-center gap-2 md:flex-col md:gap-4">
          <Tooltip arrow title="Help">
            <Link
              className="p-3 rounded-[16px] hover:bg-[#f0f0f0]"
              href={helpUrl}
              onClick={handleHelpClick}
              target="_blank"
            >
              <CustomIcon filename="help-base-grey-dark.svg" />
            </Link>
          </Tooltip>

          <Tooltip arrow title="Feedback">
            <Link
              className="p-3 rounded-[16px] hover:bg-[#f0f0f0]"
              href={feedbackUrl}
              onClick={handleFeedbackClick}
              target="_blank"
            >
              <CustomIcon filename="star-fast-base-grey-dark.svg" />
            </Link>
          </Tooltip>
        </div>
      </Box>

      {/* Bottom section: Log button (sur mobile, invisible ou passé dans un autre flux si besoin) */}
      <Box className="hidden md:block">
        <Log headerHeight={0} />
      </Box>
    </Box>
  );
}
