import { Box, Typography } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import COLORS from "../../utils/styles/colors";
import { Message } from "../../utils/types/message";
import Tooltip from "@mui/material/Tooltip";
import MessageBoxToolbar from "./MessageBoxToolbar";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";
import MessageItem from "./MesageItem";
const questionBgColor = COLORS.MESSAGE.QUESTION_BACKGROUND;
const questionFontColor = COLORS.MESSAGE.QUESTION_FONT;

const blackWhiteBlack = COLORS.BLACK_WHITE.BLACK;
const questionSx = {
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  bgcolor: questionBgColor,
  color: questionFontColor,
  padding: "10px",
  borderRadius: "6px",
  maxWidth: "100%",
};

const answerSx = {
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  padding: "20px",
  borderRadius: "6px",
  maxWidth: "100%",
  color: blackWhiteBlack,
};

export interface MessageBoxProps extends PropsWithChildren {
  readonly messageItem: Message;
}

export function MessageBox({ messageItem }: MessageBoxProps): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const isQuestion = messageItem.type === "question";

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="flex flex-col "
    >
      <Box className="flex flex-col " sx={isQuestion ? questionSx : answerSx}>
        {!isQuestion && (
          <div className="flex flex-row">
            <img
              alt="Logo Edenred"
              draggable={false}
              src="/edenred-E-logo.webp"
              className="h-4 self-center grayscale opacity-60"
            />
            <span className="p-4 font-bold text-gray-500"> Edenchat</span>
          </div>
        )}

        <MessageItem messageItem={messageItem} />
      </Box>

      <MessageBoxToolbar showActions={isHovered}>
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(messageItem.content);
            toast("Text copied to clipboard!", {
              type: "success",
              autoClose: 1500,
            });
          }}
          disableTouchRipple
          size="small"
        >
          <Tooltip title="Copy message">
            <ContentCopyIcon fontSize="small" />
          </Tooltip>
        </IconButton>
      </MessageBoxToolbar>
    </div>
  );
}
