import { Box, Button, TextField } from "@mui/material";
import {
  Dispatch,
  DragEvent,
  KeyboardEvent,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMsal } from "@azure/msal-react";

import COLORS from "../../utils/styles/colors";
import { CustomIcon } from "../base-ui";
import { Message } from "../../utils/types/message";
import { getAnswer, createNewConversation } from "../../api";
import { Parameters } from "../../utils/types/parameters";
import { ConfirmUploadDialog } from "./ConfirmUploadDialog";
import { postDocument } from "../../api/postDocument";
import TagManager from "react-gtm-module";
import { toast } from "react-toastify";

const grey100 = COLORS.BASE.GREY[100];
const grey200 = COLORS.BASE.GREY[200];
const grey300 = COLORS.BASE.GREY[300];
const grey600 = COLORS.BASE.GREY[600];
const defaultBlue = COLORS.SYSTEM.INFO.DEFAULT;
const greyDark = COLORS.BASE.GREY.DARK;
const black40 = COLORS.BASE.BLACK[40];
const white40 = COLORS.BASE.WHITE[40];

const inputSx = {
  padding: "16px",
  "& .MuiInputBase-root": {
    padding: 0,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
};

const settingsButtonSx = {
  bgcolor: greyDark,
  borderRadius: "12px",
  padding: "14px",
  border: `2px solid ${greyDark}`,
  ":hover": { bgcolor: grey600, borderColor: grey600 },
  ":focus": { borderColor: white40 },
};

const uploadButtonSx = {
  bgcolor: grey100,
  borderRadius: "12px",
  padding: "16px",
  ":hover": { bgcolor: grey200 },
  ":focus": { border: `2px solid ${black40}`, padding: "14px" },
};

export interface ChatInputProps extends PropsWithChildren {
  convId: string | null;
  convExited: boolean;
  embeddingInProgress: boolean;
  file: File | null;
  isEmpty: boolean;
  displayLoader: boolean;
  messages: Message[];
  openSettings: () => void;
  parameters: Parameters;
  ragId: string;
  setConvId: Dispatch<SetStateAction<string | null>>;
  setConvExited: Dispatch<SetStateAction<boolean>>;
  setEmbeddingInProgress: Dispatch<SetStateAction<boolean>>;
  setFile: Dispatch<SetStateAction<File | null>>;
  setFirstAnswer: Dispatch<SetStateAction<boolean>>;
  setIsEmpty: Dispatch<SetStateAction<boolean>>;
  setDisplayLoader: Dispatch<SetStateAction<boolean>>;
  setMessages: Dispatch<SetStateAction<Message[]>>;
  setText: Dispatch<SetStateAction<string>>;
  setWaitingForAnswer: Dispatch<SetStateAction<boolean>>;
  smartAssistant: boolean;
  text: string;
  transition: string;
  waitingForAnswer: boolean;
}

export function ChatInput({
  convId,
  convExited,
  embeddingInProgress,
  file,
  isEmpty,
  displayLoader,
  messages,
  openSettings,
  parameters,
  ragId,
  setConvId,
  setConvExited,
  setEmbeddingInProgress,
  setFile,
  setFirstAnswer,
  setIsEmpty,
  setDisplayLoader,
  setMessages,
  setText,
  setWaitingForAnswer,
  smartAssistant,
  text,
  transition,
  waitingForAnswer,
}: Readonly<ChatInputProps>): JSX.Element {
  const { accounts } = useMsal();
  const userToken = accounts[0]?.idToken ?? "";
  const userId = accounts[0]?.homeAccountId || "";
  const userLanguage = navigator.language || "en";
  const userLocation = "Unknown"; // Replace with actual location if available

  const [borderColor, setBorderColor] = useState<string>(grey300);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const [triggerCheck, setTriggerCheck] = useState<boolean>(false);
  const [newAnswerConvId, setNewAnswerConvId] = useState<string | null>(null);
  const [answerFetched, setAnswerFetched] = useState<boolean>(false);
  const [newAnswer, setNewAnswer] = useState<string>("");
  const [currentConvID, setCurrentConvID] = useState<string | null>(null);
  const [openConfirmUpload, setOpenConfirmUpload] = useState<boolean>(false);

  // Nouvel état pour savoir si un document a été uploadé
  const [hasUploadedDoc, setHasUploadedDoc] = useState<boolean>(false);

  const handleOpenConfirmation = () => setOpenConfirmUpload(true);
  const handleCloseConfirmation = () => setOpenConfirmUpload(false);

  const boxSx = {
    border: `2px solid ${borderColor}`,
    borderRadius: "12px",
    boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.08)",
    flexGrow: 1,
  };

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setText(event.target.value);
    setIsScrollable(event.target.scrollHeight > 40);
  }

  async function handleSubmit() {
    if (waitingForAnswer || text.trim() === "" || embeddingInProgress) {
      return;
    }
    setDisplayLoader(true);
    setWaitingForAnswer(true);
    setIsEmpty(false);
    setConvExited(false);

    const newQuestion: Message = {
      content: text,
      type: "question",
    };

    setMessages([...messages, newQuestion]);
    setText("");
    setCurrentConvID(convId);

    try {
      let apiResponseAnswer: string;
      if (convId === null) {
        const newConversationResponse = await createNewConversation(
          newQuestion.content,
          userToken,
          parameters,
          ragId
        );
        apiResponseAnswer = newConversationResponse.answer;

        setNewAnswerConvId(newConversationResponse.conversationId);
        setTriggerCheck(true);
      } else {
        apiResponseAnswer = await getAnswer(
          newQuestion.content,
          userToken,
          convId,
          parameters,
          ragId,
          !!file
        );
      }

      setNewAnswer(apiResponseAnswer);
      setAnswerFetched(true);

      setFirstAnswer(true);

      setDisplayLoader(false);
      setWaitingForAnswer(false);

      // **Push the 'send_request' event to dataLayer**
      const dataLayerArgs = {
        dataLayer: {
          event: "send_request",
          website: "EdenChat",
          env_work: process.env.NODE_ENV ?? "development",
          gpt_version: parameters.llm?.llm_name ?? "Unknown",
          temperature: parameters.temperature ?? "Unknown",
          response_length: apiResponseAnswer.length,
          user_data: {
            user_id: userId,
            user_location: userLocation,
            user_language: userLanguage,
          },
        },
      };
      TagManager.dataLayer(dataLayerArgs);
    } catch (error) {
      setDisplayLoader(false);
      setWaitingForAnswer(false);
      console.error("Error fetching data:", error);
    }
  }

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  function clickUpload() {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  }

  function filterKeyDown(evt: KeyboardEvent<HTMLInputElement>): void {
    if (!displayLoader && evt.key === "Enter" && !evt.shiftKey) {
      handleSubmit();
      evt.preventDefault();
    }
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (!event.target.files) {
      return;
    }
    setFile(event.target.files[0]);
    handleOpenConfirmation();
  }

  function handleDrop(evt: DragEvent<HTMLDivElement>) {
    evt.preventDefault();
    if (hiddenFileInput.current && !file) {
      const allowedTypes = [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      const droppedFile = evt.dataTransfer.files[0];

      if (droppedFile && allowedTypes.includes(droppedFile.type)) {
        setFile(droppedFile);
        handleOpenConfirmation();
      } else {
        alert("Only PDF and DOCX files are allowed.");
      }
    }
  }

  function handleClearFile(): void {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = "";
    }
    setFile(null);
    setHasUploadedDoc(false); // Si on annule, plus besoin de l'avertissement
  }

  function handleCancelUpload() {
    handleClearFile();
    handleCloseConfirmation();
  }

  async function handleConfirmUpload() {
    handleCloseConfirmation();
    if (file) {
      setEmbeddingInProgress(true);
      setConvExited(false);

      // **Push the 'upload' event to dataLayer**
      const dataLayerArgs = {
        dataLayer: {
          event: "upload",
          website: "EdenChat",
          env_work: process.env.NODE_ENV ?? "development",
          file_type: file.type,
          user_data: {
            user_id: userId,
            user_location: userLocation,
            user_language: userLanguage,
          },
        },
      };
      TagManager.dataLayer(dataLayerArgs);

      if (convId === null) {
        const apiReturn: string = await postDocument(userToken, file);
        if (apiReturn === "post_failed") {
          handleClearFile();
        } else {
          setNewAnswerConvId(apiReturn);
          setTriggerCheck(true);
          setHasUploadedDoc(true);
          toast("Document uploaded", { type: "success" });
        }
      } else {
        await postDocument(userToken, file);
        setHasUploadedDoc(true); // On a uploadé un document
        toast("Document uploaded", { type: "success" });
      }
      setEmbeddingInProgress(false);
    }
  }

  useEffect(() => {
    if (answerFetched) {
      const newAnswerMessage: Message = {
        content: newAnswer,
        type: "answer",
      };
      if (convId === currentConvID) {
        setMessages((prevMessages) => [...prevMessages, newAnswerMessage]);
      }
      setAnswerFetched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerFetched]);

  useEffect(() => {
    if (triggerCheck) {
      if (!convExited) {
        setConvId(newAnswerConvId);
        setCurrentConvID(newAnswerConvId);
      }
      setTriggerCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCheck]);

  // Gestion de l'avertissement avant de quitter la page si un document a été uploadé
  useEffect(() => {
    function handleBeforeUnload(e: BeforeUnloadEvent) {
      if (hasUploadedDoc) {
        e.preventDefault();
        e.returnValue =
          "You have uploaded a document. If you leave now, the uploaded data will be lost.";
        return e.returnValue;
      }
    }

    if (hasUploadedDoc) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUploadedDoc]);

  return (
    <Box className="pb-16">
      <Box
        className={`flex flex-row container md:mx-auto md:gap-4 gap-2 py-8 p-4 md:px-20 max-w-100 ${transition}`}
        onDrop={handleDrop}
      >
        <Box
          className="flex sm:flex-row items-center gap-2 sm:gap-4 w-full"
          sx={boxSx}
        >
          <div className="flex flex-row items-center gap-3 p-[14px]">
            <Box className="px-2 hidden sm:block">
              <CustomIcon
                filename={"search-normal-grey400.svg"}
                sx={{ width: "20px", height: "20px" }}
              />
            </Box>
            {file && (
              <div className="flex">
                <CustomIcon
                  filename={"document-pdf-1-base-grey-dark.svg"}
                  sx={{ width: "32px", height: "32px" }}
                />
              </div>
            )}
          </div>

          <TextField
            fullWidth
            multiline
            maxRows={isScrollable ? 6 : 1}
            placeholder="Type your message..."
            value={text}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onFocus={() => setBorderColor(defaultBlue)}
            onBlur={() => setBorderColor(grey300)}
            onKeyDown={filterKeyDown}
            sx={inputSx}
          />

          <Box className="">
            <Button
              onClick={handleSubmit}
              disabled={waitingForAnswer}
              className="h-[60px] w-[60px]"
            >
              <CustomIcon
                filename="send-2.svg"
                sx={{ width: "20px", height: "20px" }}
              />
            </Button>
          </Box>
        </Box>
        {isEmpty && !file && (
          <div className="flex flex-row items-center gap-2 md:gap-4">
            {!smartAssistant && (
              <Button
                className="flex items-center justify-center"
                onClick={clickUpload}
                sx={uploadButtonSx}
              >
                <CustomIcon filename={"document-upload-base-grey-dark.svg"} />
                <input
                  accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </Button>
            )}

            <Button
              className="flex items-center justify-center"
              onClick={openSettings}
              sx={settingsButtonSx}
            >
              <CustomIcon filename={"setting-2.svg"} white />
            </Button>
          </div>
        )}
        <ConfirmUploadDialog
          handleCancelUpload={handleCancelUpload}
          handleUpload={handleConfirmUpload}
          openConfirmation={openConfirmUpload}
          file={file}
        />
      </Box>
    </Box>
  );
}
