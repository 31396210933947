import { ParametersFromBFF } from "./types/conversationHistory";
import { isValidKey, models, ModelsType } from "./types/models";
import { defaultParameters, Parameters } from "./types/parameters";

export function formatConversationParameters(
  convParams: ParametersFromBFF
): Parameters {
  const newParameters: Parameters = {};

  newParameters.llm = defaultParameters.llm;

  newParameters.temperature = defaultParameters.temperature;
  newParameters.maxOutputToken = defaultParameters.maxOutputToken;
  newParameters.systemPrompt = defaultParameters.systemPrompt;

  if (convParams.llm_name !== null && isValidKey(convParams.llm_name)) {
    const modelInModels = models.find(
      (model: ModelsType) => model.llm_name === convParams.llm_name
    );
    newParameters.llm = modelInModels;
  }

  if (convParams.temperature !== null) {
    newParameters.temperature = convParams.temperature;
  }

  if (convParams.max_output_token !== null) {
    newParameters.maxOutputToken = convParams.max_output_token;
  }

  if (convParams.system_prompt !== null) {
    newParameters.systemPrompt = convParams.system_prompt;
  }

  return newParameters;
}
