import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

import { Message } from "../../utils/types/message";
import { MessageBox } from "./MessageBox";

export interface ChatMessagesProps extends PropsWithChildren {
  readonly chatInnerPadding: number;
  readonly displayLoader: boolean;
  readonly messages: Message[];
}

export function ChatMessages({
  chatInnerPadding,
  children,
  displayLoader,
  messages,
}: ChatMessagesProps): JSX.Element {
  return (
    <Box className={"flex flex-col w-full gap-4 container mx-auto max-w-3xl "}>
      {messages.map((messageItem, index) => {
        return (
          <div className="flex w-full" key={index}>
            {!!messageItem.content && <MessageBox messageItem={messageItem} />}
          </div>
        );
      })}

      {displayLoader && (
        <div className="flex justify-start">
          <Box
            sx={{
              height: "80px",
              width: "80px",
              marginLeft: "-10px",
            }}
          >
            <img
              alt="loader gif"
              draggable={false}
              src={"/loaderEdenred.gif"}
              style={{ objectFit: "contain" }}
            />
          </Box>
        </div>
      )}
      {children}
    </Box>
  );
}
