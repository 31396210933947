import { Box, Typography } from "@mui/material";
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
} from "react";
import { useMsal } from "@azure/msal-react";

import COLORS from "../../utils/styles/colors";
import { NormalAssistants } from "./NormalAssistants";
import { SmartAssistants } from "./SmartAssistants";
import { Parameters } from "../../utils/types/parameters";
import { AssistantFromBFF } from "../../utils/types/assistant";
import { useUserProfile } from "../../hooks/useUserProfile";
import { pushDataLayerEvent } from "../../utils/dataLayerUtils";
import { hasSharepointAccess } from "../../api/SharepointApi";
import { toast } from "react-toastify";
import { formatConversationParameters } from "../../utils/formatConversationParameters";

const black = COLORS.BLACK_WHITE.BLACK;

const titleSx = {
  background: "linear-gradient(90deg, #1077FF 0%, #9E00FF 100%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textAlign: "center" as const,
  fontSize: "54px",
  fontWeight: "700",
  lineHeight: "64px",
};
const subTitleSx = {
  color: black,
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "28px",
  textAlign: "center" as const,
};

export interface AssistantLibraryProps extends PropsWithChildren {
  readonly assistants: AssistantFromBFF[];
  readonly setLibraryOpened: Dispatch<SetStateAction<boolean>>;
  readonly setText: Dispatch<SetStateAction<string>>;
  readonly setParameters: Dispatch<SetStateAction<Parameters>>;
  readonly setNormalAssistant: Dispatch<SetStateAction<boolean>>;
  readonly setSmartAssistant: Dispatch<SetStateAction<boolean>>;
  readonly setAssistantTitle: Dispatch<SetStateAction<string>>;
  readonly setAssistantTags: Dispatch<SetStateAction<string[]>>;
  readonly setAssistantUrl: Dispatch<SetStateAction<string>>;
  readonly setRagId: Dispatch<SetStateAction<string>>;
  readonly smartAssistants: AssistantFromBFF[];
  initialRagId?: string | null;
}

function setQueryParamValue(paramName: string, paramValue: string): void {
  const url = new URL(window.location.href);
  url.searchParams.set(paramName, paramValue);
  window.history.replaceState({}, "", url.toString());
}

function shuffleArray<T>(array: T[]): T[] {
  const arr = [...array];
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

export function AssistantLibrary({
  assistants,
  setLibraryOpened,
  setText,
  setParameters,
  setNormalAssistant,
  setSmartAssistant,
  setAssistantTitle,
  setAssistantTags,
  setAssistantUrl,
  setRagId,
  smartAssistants,
  initialRagId,
}: Readonly<AssistantLibraryProps>): JSX.Element {
  useMsal();

  // Use the custom hook to get user profile data
  const userProfile = useUserProfile();

  const refScrollLibraryTitle = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (initialRagId?.length && initialRagId !== "0") {
      const smartAssistant = smartAssistants.find(
        (a) => a?.rag?.rag_id === initialRagId
      );

      if (smartAssistant?.rag_id === initialRagId) {
        handleClickSmart(
          smartAssistant.title,
          "",
          formatConversationParameters(smartAssistant.parameters),
          smartAssistant.rag.rag_tags,
          smartAssistant.rag.rag_url,
          smartAssistant.rag.rag_id
        );
      }
    }
  }, [initialRagId, smartAssistants]);

  function clearQueryParam(paramName: string): void {
    const url = new URL(window.location.href);
    url.searchParams.delete(paramName);
    window.history.replaceState({}, "", url.toString());
  }

  function handleClickNormal(
    title: string,
    question: string,
    parameters: Parameters
  ) {
    setAssistantTitle(title ?? "");
    setParameters(parameters);
    setText(question ?? "");
    setNormalAssistant(true);
    setLibraryOpened(false);

    if (userProfile) {
      const envWork = process.env.NODE_ENV ?? "development";
      const website = "EdenChat";

      const userData = {
        user_id: userProfile.userId,
        user_location: userProfile.userLocation,
        user_language: userProfile.userLanguage,
        user_bu: userProfile.userBusinessUnit,
        user_company: userProfile.userCompany,
        user_jobtitle: userProfile.userJobTitle,
      };

      pushDataLayerEvent({
        event: "assistant",
        website,
        env_work: envWork,
        assistant_name: title,
        user_data: userData,
      });
    }
  }

  const handleClickSmart = async (
    title: string,
    question: string,
    parameters: Parameters,
    tags: string[],
    url: string,
    ragId: string
  ) => {
    const hasAccess = await hasSharepointAccess(url, tags);

    if (hasAccess) {
      setQueryParamValue("ragId", ragId);
      setAssistantTitle(title ?? "");
      setParameters(parameters);
      setText("");
      setAssistantTags(tags);
      setAssistantUrl(url);
      setRagId(ragId);
      setSmartAssistant(true);
      setLibraryOpened(false);
    } else {
      clearQueryParam("ragId");
      toast.error(
        "Restricted Content: you don't have access to this space. Please contact the business owner for access to the SharePoint site",
        {
          autoClose: 4000,
        }
      );
    }

    if (userProfile) {
      const envWork = process.env.NODE_ENV ?? "development";
      const website = "EdenChat";

      const userData = {
        user_id: userProfile.userId,
        user_location: userProfile.userLocation,
        user_language: userProfile.userLanguage,
        user_bu: userProfile.userBusinessUnit,
        user_company: userProfile.userCompany,
        user_jobtitle: userProfile.userJobTitle,
      };

      pushDataLayerEvent({
        event: "assistant",
        website,
        env_work: envWork,
        user_has_access: hasAccess,
        assistant_name: title,
        user_data: userData,
      });
    }
  };

  const ScrollToLibraryTitle = () =>
    refScrollLibraryTitle.current?.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    ScrollToLibraryTitle();
  }, []);

  return (
    <Box className="flex flex-col w-full items-center gap-[40px] px-[40px]">
      <Box className="flex flex-col w-full items-center gap-6">
        <Typography ref={refScrollLibraryTitle} sx={titleSx}>
          Assistant library
        </Typography>
        <Typography sx={subTitleSx}>
          Discover Assistants: prompt examples and pre-configured bots to help
          you get started!
        </Typography>
      </Box>

      <Box className="flex flex-col w-full items-center overflow-hidden gap-[80px]">
        <SmartAssistants
          smartAssistants={smartAssistants}
          clickAssistant={handleClickSmart}
        />
        <NormalAssistants
          assistants={assistants}
          clickAssistant={handleClickNormal}
        />
      </Box>
    </Box>
  );
}
